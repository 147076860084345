import React from "react";
import { Link, NavLink  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.css";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import sunft from '../img/sun-ft.png';
import citymoon from '../img/cityMoon.jpg';
import nightmoon from '../img/night-moon.png';

export const Ft = () => {
  let url='#';
  let sgaemail='spiritguide07@gmail.com';
  let ytastro='https://www.youtube.com/playlist?list=PLzvSAbWoOuUkWbQe4T7c1eTk-y8EpM9Pn';
  let fbajour='https://www.facebook.com/AstrologicalJourney';
  return (
    <>
    <br/>
    <div className='ffooter'>
    <div className='fcontainer'>
      <div className='footer-main'>
        <div className='footer-column'>
          <h3 className='footer-heading'>
	    <img src={sunft} alt="features"/>
	  </h3>
            <a href='/bookreading' className='footer-link'>Book a Reading</a>
            <a href="https://www.amazon.com/stores/Jack%20Hopkins/author/B09WX8FMRP"
	       className='footer-link'>My Books</a>
            <a href='/howto' className='footer-link'>How To</a>   
            <a href='/about' className='footer-link'>About</a>   
        </div>
        <div className='footer-column'>
          <h3 className='footer-heading'>
	    <img src={citymoon} alt="chart info"/>
	  </h3>
            <a href='/charts' className='footer-link'>Chart Library</a>
            <a href='/lphases' className='footer-link'>Lunar Phases</a>
            <a href='/sunmoon' className='footer-link'>Sun & Moon</a>   
            <a href='/tlevents' className='footer-link'>Timelines</a>   
        </div>
        <div className='footer-column'>
          <h3 className='footer-heading'>
	    <img src={nightmoon} alt="videos"/>
	  </h3>
            <a href='/' className='footer-link'>Home</a>   
            <a href='/ytvideos' className='footer-link'>Videos</a>   
            <a href='/logIn' className='footer-link'>Log In</a>   
            <a href='/register' className='footer-link'>SignUp</a>   
        </div>
        </div>
        <div className='footer-bottom'>          
          <span className='copyright'>spiritguide7@gmail.com&nbsp;</span>
          <a href={ytastro} className='footer-social'>
          <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a> 
          <a href={fbajour} className='footer-social'>
          <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a> 
          <a href={url} className='footer-social'>
          <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>         
        </div>
     
    </div>
    </div>
    </>
  )
}
export default Ft;

