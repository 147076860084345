import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import {useNavigate} from "react-router-dom";
import "../App.css";
import { IconContext } from "react-icons";

function Navbar() {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const lsReg = localStorage.getItem('sgaRegister');
  const goHome = () => {
   navigate('/');
  }
  return (
    <>
      <IconContext.Provider value={{ color: "undefined" }}>
        <div className="navbar2" onClick={goHome}>
          <Link to="#" className="menu-bars2">
            <FaIcons.FaBars onClick={showSidebar} />
          </Link> 
          <div className="navbar-title2">Spirit Guide Astrology</div> 
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
      
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
            </li>
            {SidebarData.map((item, index) => {
	      if (item.pub_access || 
		  lsReg == 'sga@gmail.com' || 
		 (lsReg != null && lsReg != 'guest@gmail.com' && item.title != 'TL Edit') )
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}&nbsp;
                    <span>{item.title}</span>
                  </Link>
                </li>
		);
	     })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
