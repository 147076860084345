import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  createRoutesFromElements,
} from "react-router-dom";
import Store from "./components/Store";
import Navbar from "./components/Navbar";
import Ft from "./components/Ft";
import Home from "./pages/Home";
import Charts from "./pages/Charts";
import LunarPhases from "./pages/LunarPhases";
import YTVideos from "./pages/YTVideos";
import NewChartX from "./pages/NewChartX";
import Register from "./pages/Register";
import TLEdit from "./pages/TLEdit";
import TLEvents from "./pages/TLEvents";
import TLBook from "./pages/TLBook";
import SunMoon from "./pages/SunMoon";
import LPhaseLookup from "./pages/LPhaseLookup";
import BookReading from "./pages/BookReading";
import aiLPhase from "./pages/aiLPhase";
//import EmailContactForm from "./pages/EmailContactForm";
import LogIn from "./pages/LogIn";
import About from "./pages/About";
import HowTo from "./pages/HowTo";
import "./App.css";

const AppLayout = () => (
  <>
    <Store>
    <Navbar />
    <Outlet />
    <Ft />
    </Store>
  </>
);

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "charts",
        element: <Charts />,
      },
      {
        path: "newchart",
        element: <NewChartX />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "tledit",
        element: <TLEdit />,
      },
      {
        path: "tlevents",
        element: <TLEvents />,
      },
      {
        path: "tlbook",
        element: <TLBook />,
      },
      {
        path: "sunmoon",
        element: <SunMoon />,
      },
      {
        path: "lphaselookup",
        element: <LPhaseLookup />,
      },
      {
        path: "ytvideos",
        element: <YTVideos />,
      },
      {
        path: "lphases",
        element: <LunarPhases />,
      },
      {
        path: "login",
        element: <LogIn />,
      },
      {
        path: "howto",
        element: <HowTo />,
      },
      {
        path: "bookreading",
        element: <BookReading />,
      },
      {
        path: "about",
        element: <About />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
